import './App.css';

import Login from './Components/Login.js';
import MainPage from './Components/MainPage.js';
import IconizedMenu from './Components/IconizedMenu.js';

import React, { useEffect, useState } from "react";
import { ApiKey, dataUrl, LoginUrl} from './config.js'
import {ApplyTheme} from './Components/DarkModeToggle.js';
import axios from 'axios';
import BreakScreen from './Components/BreakScreen';
import Spinner from './Components/Spinner.js';


function App() {
  const [subjects, setSubjects] = useState(null);
  const [revisions, setRevisions] = useState(null);
  const [selsubject, setSelSubject] = useState(null);
  const [showBreakScreen, setshowBreakScreen] = useState(false);
  const [showIconizedMenu, setshowIconizedMenu] = useState(true);
  const [refreshTokenCount, setRefreshTokenCount] = useState(0);
  const [showBreakScreenError, setshowBreakScreenError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [forceRefresh, setForceRefresh] = useState(0);
  useEffect(() => {
    const preventSaveShortcut = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', preventSaveShortcut);


    if(localStorage.getItem('user')===null)
      {
        setIsLoading(false);
        setSubjects(null);
        setRevisions(null);
      }
    else{
      var isSunMode = localStorage.getItem('islightmode')!=null ? JSON.parse(localStorage.getItem('islightmode')) : true;
      ApplyTheme(isSunMode);
      sessionStorage.clear();
      var reqsubjects = {
        email: localStorage.getItem('user'),
        method: 'GetMenu'
      };
      const headers = { 
        'x-api-key': ApiKey
      };
      axios.post(dataUrl, reqsubjects, { withCredentials: true, headers:headers}).then(resp => {
        console.log(resp.data.Menu);
              setSubjects(resp.data.Menu);
              setRevisions(resp.data.Revisions);
              setIsLoading(false);
      }).catch(err => {FailedReq();});
  }

  return () => {
    window.removeEventListener('keydown', preventSaveShortcut);
  };
  }, []);

  function updateRevisions(revisions)
  {
    setRevisions(revisions);
  }

  useEffect(() => {
    // When subjects loaded for the first time.
    // in 50 min break.
    const breakepoch = localStorage.getItem('breaktime');
    
    if(!breakepoch)
      return;

   const breaktime = 52*60*1000;//50
    var millsec  = breaktime - (new Date().getTime() - breakepoch);

    if( millsec<=0)
      {
        FailedReq();
        return;
      }
    const timer = setTimeout(() => {
      if(localStorage.getItem('user'))
        setshowBreakScreen(true);
    }, millsec)
    return () => clearTimeout(timer);
  },  [refreshTokenCount]);

  // we are storing the email in local storage so no need to login every time.
  function FailedReq()
  {
    // Todo:: server call to delete the token.
    // Dirty fix clean childs and then parents.
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(function(){
    setSubjects(null);
    setRevisions(null);
    setIsLoading(false);
    setshowBreakScreen(false);
    },1000);
  }


  function RefreshSession(password)
  {
    setIsLoading(true);
    var req = {
      email: localStorage.getItem('user'),
      password: password
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    axios.post(LoginUrl, req, { withCredentials: true, headers:headers}).then(
      resp=>{ 
        localStorage.setItem('breaktime', new Date().getTime() );
        setshowBreakScreen(false);
        setRefreshTokenCount(refreshTokenCount+1);
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
        setshowBreakScreenError("Failed refreshing session, incorrect password entered!");
        setTimeout(() => {
          setshowBreakScreenError(null);
        }, 3000);
    });  
  }

  function selectSubject(sub)
  {
    setSelSubject(sub);
    setForceRefresh(forceRefresh+1);
    setshowIconizedMenu(false);
  }

  function loginSuccess(email)
  {
    localStorage.setItem('user', email);
    var reqsubjects = {
      email: localStorage.getItem('user'),
      method: 'GetMenu'
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    axios.post(dataUrl, reqsubjects, { withCredentials: true, headers:headers}).then(resp => {
            localStorage.setItem('breaktime', new Date().getTime() );
            setSubjects(resp.data.Menu);
            setRevisions(resp.data.Revisions);
            setRefreshTokenCount(refreshTokenCount+1);
    }).catch(err => {FailedReq();})
  }
  // Todo show iconized menu toggleable instead do nothing.
  function ToggleIconizedMenu()
  {
    if(selsubject===null)
      return;
    setshowIconizedMenu(!showIconizedMenu);
  }
 
  //{loggedInUser ? <MainPage/> : <Login loggedInUser={loggedInUser} OnSuccess={loginSuccess}/>
  return (
      <div className='App'>
        { isLoading ? <Spinner wholepage={true}/> : <div>
        {showBreakScreen && <BreakScreen backToLogin={FailedReq} OnContinue={RefreshSession} breakError={showBreakScreenError}/>}
        {subjects && showIconizedMenu && <IconizedMenu subjects={subjects} passselectedsub={selectSubject} toggleIconizedMenu={ToggleIconizedMenu}/>}
        {subjects ? (selsubject && <MainPage key={forceRefresh} backToLogin={FailedReq} subjects={subjects} opensubject={selsubject} showIconizedMenu={ToggleIconizedMenu} revisions={revisions} onRevisionsUpdate={updateRevisions}/> ): <Login OnSuccess={loginSuccess}/>}
        </div>
        }
      </div>
  );
}

export default App;