import React, { useRef } from 'react'
import {useState, useEffect} from "react";
import axios from 'axios';
import { ApiKey, dataUrl, stylesFS, HighlightSvg, CommentSvg} from '../config.js'
import CommentBox from './CommentBox.js';
import Subscribe from './Subscribe';
import NoPrintPage from './NoPrint'
import Spinner from './Spinner';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './ContentPage.css'
import PanZoom from 'react-image-pan-zoom-rotate';
import ContentProtect from './ContentProtect.js';
import RevisionSchedulerButtonsAndTextBox from './RevisionScheduler.js';
var selectionmodifstate = {
  highlight: 1,
  comment: 2,
  removehighlight: 4,
  removecomment: 8
}


export default function ContentPage({ backToLogin, subject, tK, topic, type, saveState, TopicsList, LoadContentPage, revisions, onRevisionsUpdate, navigationParams}) {
  const [contenthtml, setContentHtml] = useState(null);
  const [nextTopic, setNextTopic] = useState(null);
  const [prevTopic, setPrevTopic] = useState(null);
  const [fromSearchPagethenscrollto, setfromSearchPagethenscrollto] = useState(null);


  useEffect(()=>{
    if(TopicsList!==null)
      for (var i = 0; i < TopicsList.length; i++) {
        if(topic===TopicsList[i].Topic)
        {
          if(i!==0)
            setPrevTopic(TopicsList[i-1].Topic);
          if(i!==TopicsList.length-1)
            setNextTopic(TopicsList[i+1].Topic);
          break;
        }
      }
    var req = {
      email: localStorage.getItem('user'),
      method: 'GetContent',
      subject: subject,
      params: {
        TK: tK,
        Topic: topic
      }
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
      //var data = JSON.parse(resp.data);
      //todo:: redirect to payment page.
      setContentHtml(resp.data);
    }).catch(err => backToLogin());
  }, []);


  function ScrollToBasedOnNavigationParams()
  {      
    const paragraphs = document.getElementsByTagName('span');

    // Iterate over each paragraph and find the first match
    for ( let i = 0; i< paragraphs.length; i++) {
        if (paragraphs[i].textContent.toLowerCase().includes(navigationParams)) {
            let id = paragraphs[i].id;
             // Calculate position to scroll to
             setfromSearchPagethenscrollto(id);
            break;
        }
    }
  }


  useEffect(() => {
    if(!navigationParams)
      return;
    // required for safari.
    const timer = setTimeout(() => {
      ScrollToBasedOnNavigationParams();
    }, 500);
    // Cleanup timeout
    return () => {
      clearTimeout(timer);
    };
    }, [contenthtml]);

    useEffect(() => {
      if(fromSearchPagethenscrollto!=null)
        {
          document.getElementById(fromSearchPagethenscrollto).classList.add('SearchHighlight');
          document.getElementById(fromSearchPagethenscrollto).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'});
          const timer = setTimeout(() => {
            document.getElementById(fromSearchPagethenscrollto).classList.remove('SearchHighlight'); 
          }, 3000);
            return () => clearTimeout(timer);
        } 
    }, [fromSearchPagethenscrollto]);

  return (
     //<div className="ContentPage" dangerouslySetInnerHTML={{__html: contenthtml}} />
     <div>
       {contenthtml ?  (contenthtml.Locked ? <Subscribe msg={contenthtml.message} backToLogin={backToLogin} /> : 
       <div>
        <ContentDisplay contentBody={contenthtml} tK={tK} topic={topic} subject={subject} type={type} saveState={saveState} revisions={revisions} onRevisionsUpdate={onRevisionsUpdate}/>
        <div className='NextPrevGroup'>
          {prevTopic && LoadContentPage &&  <div className="Prevbutton" onClick={() => {LoadContentPage(subject, tK, prevTopic, type, TopicsList)}}>{"<prev"}</div>}
          {nextTopic && LoadContentPage && <div className="Nextbutton" onClick={() => {LoadContentPage(subject, tK, nextTopic, type, TopicsList)}}>{"next>"}</div>}
        </div>
        </div>)
       : <Spinner wholepage={true}/>}
     </div>
  )
}

function ContentDisplay({contentBody, tK, topic, subject, type, saveState, revisions, onRevisionsUpdate})
{
  // store all the transients and commit to database on page exit.
  // Load all highlights into this set and while closing the page commit them to DB
  // one item commit with change tracking variable.
  // So upon showing if there are certain highlights that cannot be shown they can be removed.
  //Todo :: reduce the redundancy and promote reusability.
  // Todo:: highlight should support comment.
  var highlightsToBeSavedtoDB = useRef(new Map());
  var commentsToBeSavedtoDB = useRef(new Map());
  var bHighlightsChanged = useRef(false);
  var bRevisionScheduleChanged = useRef(false);
  var bCommentsChanged = useRef(false);
  var bRevisionNotesChanged = useRef(false);
  var saveemail = useRef(localStorage.getItem('user'));

  // Set inital state of revision notes from DB call.
  const stylePath = useRef(stylesFS + (type==="PDF2HTML" ? tK.toLowerCase() + '_' + topic + "/" : "" ) + tK.toLowerCase() + '_' + topic + '.css');
  const contentMenuSection = useRef(null);
  const [revisionSchedule, setrevisionSchedule] = useState(null);
  const revisionScheduleref = useRef(null);
  revisionScheduleref.current = revisionSchedule;
  const [contentContextMenu, setContentContextMenu] = useState({visible: false, type: {highlightstate: 0, highlightreference: null, commentstate:0, commentreference: null}});
  const [openMenu, setOpenMenu] = useState(true);
  const [ContentSBTabState, setContentSBTabState] = useState(1);
  const [navmenu, setnavmenu] = useState(null);
  const [value, setValue] = useState('');
  const RNValue = useRef('');
  RNValue.current = value;
  const [fullScreenChecked, setFullScreenChecked] = useState(false); 
  const [screenOrientation, setScreenOrientation] = useState('');
  const [screenOrientationwarning, setScreenOrientationWarning] = useState(false);
  const [screenOrientationWarningTimerId, setScreenOrientationWarningTimerId] = useState(null);

  const [imageSrc, setImageSrc] = useState("");
  const panZoomRef = useRef(null);
  const [showCommentBox, setShowCommentBox] =  useState(false);
  const [showContentProtection, setShowContentProtection] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // State to track if saving




  function commentBoxOnDeleteComment(reference)
  {

    PerformAction( selectionmodifstate.removecomment , {highlightstate: 0, highlightreference: null, commentstate:0, commentreference: reference});
    setShowCommentBox(null);
  }
  function commentBoxOnChangeComment(newcomment, ref)
  {
    bCommentsChanged.current = true;
    const commentObject = commentsToBeSavedtoDB.current.get(ref);
    commentObject.comment = newcomment;
  }
  useEffect(() => {
    if (!isSaving) {
      setIsSaving(true);
      saveToDb() // Function to save data to DB
        .then(() => setIsSaving(false));
      }
  }, [saveState]);


 
  function onCPMenuItem(scrollIndex)
    {
      document.querySelectorAll('h1[class^=CivilsCode], p span[class^=CivilsCodeHeading1Char], h2[class^=CivilsCode], p span[class^=CivilsCodeHeading2Char]')[scrollIndex].scrollIntoView();
    }

  function HtmlList(data)
  {
   return data.map((heading, index) => ( heading.innerText.trim().length>1 && <li className={heading.nodeName==='H2' || heading.className === "CivilsCodeHeading2Char"  ? "subheading"  : (heading.nodeName==='H3' || heading.className === "CivilsCodeHeading3Char" ? "subsubheading" : "CPSidebarMenuItem bigheading") } key={index} onClick={(e) => {onCPMenuItem(index)}} >{heading.innerText.trim()}</li>));
  }
  function GetRevisionNotesAndShowThem()
  {
    var req = {
      email: saveemail.current,
      method: 'GetRevisionNotes',
      subject: subject,
      params: {
        TK: tK,
        Topic: topic,
      }
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
      setValue(resp.data);
    }).catch(err => console.log(err));
  }
  function GetHighlightsRevisionScheduleCommentsAndShowThem()
  {
    var req = {
      email: localStorage.getItem('user'),
      method: 'GetHighlights',
      subject: subject,
      params: {
        TK: tK,
        Topic: topic,
        Type: type
      }
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    // Highlight is just a string object while comment is [string, string] object
    // so in merged list we have highlight stored as [string, null]
    let highlightCommentMergedList = [];
    function highlightCommentsort(item1, item2)
    {
      const highlight1 = item1[0].split(',');
      const highlight2 = item2[0].split(',');
      for (let i = 0; i < 4; i++) {
        if(Number(highlight2[i])!==Number(highlight1[i]))
          return Number(highlight2[i]) - Number(highlight1[i]);
      }
    }
    const highlightsres = axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
      setrevisionSchedule(resp.data.RevisionSchedule ? {NextRevisionDate: resp.data.RevisionSchedule.NextRevisionDate, PreviousOffset: resp.data.RevisionSchedule.Offset, RevisionCounter : resp.data.RevisionSchedule.RevisionCounter, PreviousDateOfRevision: resp.data.RevisionSchedule.PreviousDateOfRevision }: null);
      resp.data.result.map((item) => highlightCommentMergedList.push([item, null]));
    }).catch(err => console.log(err));
    var commentreq = {
      email: localStorage.getItem('user'),
      method: 'GetComments',
      subject: subject,
      params: {
        TK: tK,
        Topic: topic,
      }
    };
    const commentsres =  axios.post(dataUrl, commentreq, { withCredentials: true, headers:headers}).then(resp => {
        resp.data.map((item) => highlightCommentMergedList.push([item[0], item[1]]));

      }).catch(err => console.log(err));
    Promise.all([highlightsres, commentsres])
    .then(() => {
      highlightCommentMergedList.sort(highlightCommentsort);
      highlightCommentMergedList.map((item) => ShowHighlightOrComment(item[0], item[1]));
    });

  }
  function ShowHighlightOrComment(valuelocal, comment=null)
    {
      var newRangeObjectLocal = document.createRange();
      const highlight = valuelocal.split(',');
      try{
         newRangeObjectLocal.setStart(document.getElementById(Number(highlight[0])).firstChild,  Number(highlight[2]));
      newRangeObjectLocal.setEnd(document.getElementById(Number(highlight[1])).firstChild, Number(highlight[3]));
         }
      catch{
        return;
      }
      var startNode = newRangeObjectLocal.startContainer;
      var endNode = newRangeObjectLocal.endContainer;
      var startIndex = newRangeObjectLocal.startOffset;
      var endIndex = newRangeObjectLocal.endOffset;

      if(startNode.isSameNode(endNode))
        {
          var spanHighlight = document.createElement('span');
          var newRangeObjectLocal2 = document.createRange();
          spanHighlight.className = comment!=null ? "Comment showcommentpin" : 'Highlight';;
          var index = SelectionToSaveintoList(startNode, endNode, startIndex, endIndex, newRangeObjectLocal);
          spanHighlight.setAttribute(comment!=null ? "commentreference" :"highlightreference", index.toString());
          // already highlighted cannot be highlighted again since no selection can be made.
          newRangeObjectLocal.surroundContents(spanHighlight);
          newRangeObjectLocal2.setStart(spanHighlight.firstChild, 0);
          newRangeObjectLocal2.setEnd(spanHighlight.firstChild, spanHighlight.firstChild.nodeValue.length);
          if(comment!=null)
              commentsToBeSavedtoDB.current.set(index.toString() , {range: newRangeObjectLocal2, comment: comment });
          else
              highlightsToBeSavedtoDB.current.set(index.toString() , newRangeObjectLocal2);
          return;
        }

        var _iterator = document.createNodeIterator(
          newRangeObjectLocal.commonAncestorContainer,
          NodeFilter.SHOW_ALL, // pre-filter
          {
              // custom filter
              // Already selected then no reselection and overlap is treated as two objects.
              acceptNode: function (node) {
                  return newRangeObjectLocal.intersectsNode(node) && node.nodeValue!= null && node.nodeValue.trim()!=='' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
              }
          }
      );
      var _nodes = [];
      // If there is already a highlight and we select over it then we should not allow.
      // We cannot delete the existing highlight that will take user by surprise.
      while (_iterator.nextNode()) {
          if (_nodes.length === 0 && _iterator.referenceNode !== startNode)
          {
            continue;
          }
        // Already highlighted node.
        _nodes.push(_iterator.referenceNode);
          if (_iterator.referenceNode === endNode) 
            {
              break;
            }
      }
      let newRangeObjectMultiLocal = document.createRange();
      let indexMultiLocal =  SelectionToSaveintoList(startNode, 
        endNode, 
        startIndex, 
        endIndex, newRangeObjectLocal);
      for(var i = 0; i<_nodes.length; i++)
        {
          let rangeLocal = document.createRange();
          let spanHighlightLocal = document.createElement('span');
          spanHighlightLocal.className = comment!=null ? 'Comment' : 'Highlight';
          if(i===0)
            {
              if( comment!=null )
                spanHighlightLocal.className += ' showcommentpin';
              rangeLocal.setStart(startNode, startIndex);
              rangeLocal.setEnd(startNode, startNode.nodeValue.length);
            }
          else if(i===_nodes.length-1)
            {
              rangeLocal.setStart(endNode, 0);
              rangeLocal.setEnd(endNode, endIndex);
            }
          else
            {
              if(_nodes[i].nodeType===document.TEXT_NODE)
              {
                rangeLocal.setStart(_nodes[i], 0);
                rangeLocal.setEnd(_nodes[i], _nodes[i].nodeValue.length);
              }
              else if(_nodes[i].nodeType===document.ELEMENT_NODE)
              {

                _nodes[i].parentNode.insertBefore(spanHighlightLocal, _nodes[i]);
                spanHighlightLocal.appendChild(_nodes[i]);
                for(var childMulti=spanHighlight.firstChild; childMulti!==null; childMulti=childMulti.nextSibling) {
                  newRangeObjectMultiLocal.selectNode(childMulti);
                }  

                continue;
              }
            }
            rangeLocal.surroundContents(spanHighlightLocal);
            if(i===0)
              {
                newRangeObjectMultiLocal.setStart(spanHighlightLocal.firstChild, 0);
              }
            if(i===_nodes.length-1)
              {
                newRangeObjectMultiLocal.setEnd(spanHighlightLocal.firstChild, spanHighlightLocal.firstChild.nodeValue.length);
              }
              spanHighlightLocal.setAttribute(comment!=null ? 'commentreference' : 'highlightreference', indexMultiLocal);      
            /*for(let childMulti=spanHighlightLocal.firstChild; childMulti!==null; childMulti=childMulti.nextSibling) {
              newRangeObjectMultiLocal.selectNode(childMulti);
            }*/          
       }
       if(comment!==null)
          commentsToBeSavedtoDB.current.set(indexMultiLocal.toString() , {range: newRangeObjectMultiLocal, comment: comment});
      else
          highlightsToBeSavedtoDB.current.set(indexMultiLocal.toString() , newRangeObjectMultiLocal);
       highlightsToBeSavedtoDB.current.set(indexMultiLocal.toString() , newRangeObjectMultiLocal);
       return;
      
    }
  function SaveRevisionNotesToDB()
    {
      if(bRevisionNotesChanged.current===false)
        return Promise.resolve();;
      var req = {
          email: saveemail.current,
          method: 'SaveRevisionNotes',
          subject: subject,
          params: {
            TK: tK,
            Topic: topic,
            RevisionNotes: RNValue.current
          }
        };
        const headers = { 
          'x-api-key': ApiKey
        };
        return axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
          //var data = JSON.parse(resp.data);
          //todo:: redirect to payment page.
          bRevisionNotesChanged.current = false;
          console.log(req);
        }).catch(err => console.log(err));
      
    }
    function SaveRevisionSchedule()
    {
      if(bRevisionScheduleChanged.current===false)
        return Promise.resolve();
      let separator = '^';
      var req = {
        email: saveemail.current,
        method: 'SaveRevisionSchedule',
        subject: subject,
        params: {
          TK: tK,
          Topic: topic,
          Type: type,
          NextRevisionDate: revisionScheduleref.current.NextRevisionDate,
          Offset: revisionScheduleref.current.Offset
        }
      };
      const headers = { 
        'x-api-key': ApiKey
      };
      return axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
        //var data = JSON.parse(resp.data);
        //todo:: redirect to payment page.
        bRevisionScheduleChanged.current = false;
        let newState = [...revisions];

        // Update the specific index with the new value
        const revisionpage = subject + separator + tK.toLowerCase() + separator + topic + separator + type;
        let foundIndex = newState.findIndex(map => map['Navigation'] === revisionpage);
        if(foundIndex===-1)
          newState.push({Navigation: revisionpage, NextRevisionDate: new Date(revisionScheduleref.current.NextRevisionDate).toISOString(), Offset: revisionScheduleref.current.Offset, PreviousDateOfRevision: new Date().toISOString(), RevisionCounter: 1});
        else{
        newState[foundIndex].NextRevisionDate = new Date(revisionScheduleref.current.NextRevisionDate).toISOString();
        newState[foundIndex].Offset = revisionScheduleref.current.Offset;
        newState[foundIndex].PreviousDateOfRevision = new Date().toISOString();
        newState[foundIndex].RevisionCounter = (parseInt(newState[foundIndex].RevisionCounter)+1).toString();
        }
        // Update the state with the new state
        onRevisionsUpdate(newState);
      }).catch(err => console.log(err));
    }
  function SaveHighlgihtsToDB()
    {
      if(bHighlightsChanged.current===false)
        return Promise.resolve();;
      //Update all the highlights in DB
      let highlightList = [];
      highlightsToBeSavedtoDB.current.forEach(function(valuelocal, key) {highlightList.push(key);});
      var req = {
        email: saveemail.current,
        method: 'SaveHighlights',
        subject: subject,
        params: {
          TK: tK,
          Topic: topic,
          Highlights: highlightList
        }
      };
      const headers = { 
        'x-api-key': ApiKey
      };
      return axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
        //var data = JSON.parse(resp.data);
        //todo:: redirect to payment page.
        bHighlightsChanged.current = false;
        console.log(req);
      }).catch(err => console.log(err));
      }
    function SaveCommentsToDB()
    {
      if(bCommentsChanged.current===false)
        return Promise.resolve();
      //Update all the highlights in DB
      let commentList = [];
      commentsToBeSavedtoDB.current.forEach(function(valuelocal, key) {commentList.push([key, valuelocal.comment]);});
      var req = {
        email: saveemail.current,
        method: 'SaveComments',
        subject: subject,
        params: {
          TK: tK,
          Topic: topic,
          Comments: commentList
        }
      };
      const headers = { 
        'x-api-key': ApiKey
      };
      return axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
        //var data = JSON.parse(resp.data);
        //todo:: redirect to payment page.
        bCommentsChanged.current = false;
        //console.log(req);
      }).catch(err => console.log(err));
    }
  const OnPageScroll = () => {
    if (window.getSelection) {window.getSelection().removeAllRanges();}
    else if (document.selection) {document.selection.empty();}
    setContentContextMenu({visible:false, type: {highlightstate: 0, highlightreference: null, commentstate:0, commentreference: null}});
  };
  useEffect(()=> {
    if (screenOrientationWarningTimerId) {
      clearTimeout(screenOrientationWarningTimerId); // Clear any existing timer
    }
    if(screenOrientation==="landscape")
    {
      document.documentElement.style.marginRight = "25%";
      setImageSrc(null);
      setOpenMenu(true);
      setScreenOrientationWarning(false);
    }
  else
    {
      setScreenOrientationWarning(true);
      document.documentElement.style.marginRight = "0%";
      const newTimerId = setTimeout(() => {setScreenOrientationWarning(false)}, 7000);
      setScreenOrientationWarningTimerId(newTimerId);
    }

  }, [screenOrientation] );

  const handleImgClick = event => {
    if(screenOrientation==="portrait")
      return;
    if(event.target.tagName === 'IMG') {
      setImageSrc(event.target.src);
    }
  }

  const closeImageViewer = () => {
    setImageSrc(null);
  }
  const saveToDb = async () => {
    console.log("saving data");
    await SaveRevisionNotesToDB();
    await SaveHighlgihtsToDB();
    await SaveRevisionSchedule();
    await SaveCommentsToDB();
  };





  useEffect(() => {

    const checkOrientation = () => {
      setScreenOrientation(window.innerHeight > window.innerWidth ? 'portrait' : 'landscape');
    };
    window.addEventListener('resize', checkOrientation);
    
    const handleBlur = () => {
      // we can do it with state vars but want to make it with JS for better protection.
      setShowContentProtection(true);
    }
    const handleFocus = () => 
      {
        setShowContentProtection(false);

      }

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
    checkOrientation();
    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = stylePath.current + "?version=" + Math.floor(Math.random()*999999999).toString();
    link.async = true;

    head.appendChild(link);
    var menulist =  [...document.querySelectorAll('h1[class^=CivilsCode], p span[class^=CivilsCodeHeading1Char], h2[class^=CivilsCode], p span[class^=CivilsCodeHeading2Char]')];
    if(menulist.length!==0)
      setnavmenu(HtmlList(menulist));
    else // select only revision notes.
      setContentSBTabState(2)
    GetHighlightsRevisionScheduleCommentsAndShowThem();
    GetRevisionNotesAndShowThem();
    window.addEventListener("scroll", OnPageScroll);
    // Todo:: one call instead four calls.
    const CleanUp = (event) => {
      if(!isSaving)
      {
        event.preventDefault();
        console.log("saving data in cleanup");
        setIsSaving(true);
        saveToDb().then(() => setIsSaving(false))
        event.returnValue = '';
      }
    }
    
    window.addEventListener('beforeunload', CleanUp);
    if(type!=='PDF2HTML')
      {
        window.addEventListener('click', handleImgClick);
        window.addEventListener('touchstart', handleImgClick);
      }

    // Get user information from the server and display.

    return () => { 
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('beforeunload', CleanUp);
      window.removeEventListener('resize', checkOrientation)
      if (!isSaving) {
        console.log("saving data");
        setIsSaving(true);
        saveToDb() // Function to save data to DB
          .then(() => setIsSaving(false));
       }
      head.removeChild(link); 
      document.documentElement.style.marginRight = "0%";
      window.removeEventListener("scroll", OnPageScroll);
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
      if(type!=='PDF2HTML')
        {
          window.removeEventListener('click', handleImgClick);
          window.removeEventListener('touchstart', handleImgClick);
        }
    }

  }, []);


  function SelectionToSaveintoList(startingNode, endingNode, startingOffset, endingOffset, rangeobject)
    {
      var start_parent = (() => {
        var parent = startingNode.parentElement;
        for(; !parent.hasAttribute("id") ; )
          {
            parent = parent.parentElement;
          }
        return parent;
      })();
      var end_parent = (() => {
        var parent = endingNode.parentElement;
        for(; !parent.hasAttribute("id") ; )
          {
            parent = parent.parentElement;
          }
        return parent;
      })();
     // https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container
      var preCaretRange = rangeobject.cloneRange();
      preCaretRange.selectNodeContents(start_parent);
      preCaretRange.setEnd(startingNode, startingOffset);
      let start = preCaretRange.toString().length;
      preCaretRange = rangeobject.cloneRange();
      preCaretRange.selectNodeContents(end_parent);
      preCaretRange.setEnd(endingNode, endingOffset);
      let end = preCaretRange.toString().length;
      var arraylist = [start_parent.getAttribute("id"), end_parent.getAttribute("id"),  start, end];
      return arraylist.join();
    }
  function DoneWithContextMenu()
  {
    setContentContextMenu(prevState => {
      return {
        visible: false,
        type:{
          commentstate:0, commentreference:null,
          highlightstate:0, highlightreference:null
        }
    }
     }
);
  }

  // Todo:: merge highlights and comments so there are no multiple calls to DB. Get in one call and put in one call.
  function PerformAction(actionId, type)
  {
    // Highlight given text
    if(actionId===selectionmodifstate.highlight || actionId===selectionmodifstate.comment)
      {
        if(window.getSelection().toString().trim()==='')
          return;
        var range =  window.getSelection().getRangeAt(0);
        var startNode = range.startContainer;
        var endNode = range.endContainer;
        var startIndex = range.startOffset;
        var endIndex = range.endOffset;
        if(startNode.isSameNode(endNode))
          {
            // index correction approach
          if(startNode.parentElement.hasAttribute('commentreference') ||  startNode.parentElement.hasAttribute('highlightreference'))
            {
            DoneWithContextMenu();
            return;
            }
          var spanHighlight = document.createElement('span');
          var newRangeObjectLocal = document.createRange();
          spanHighlight.className = actionId===selectionmodifstate.comment ? "Comment showcommentpin" : 'Highlight';
          var index = SelectionToSaveintoList(startNode, endNode, startIndex, endIndex, range);
          spanHighlight.setAttribute(actionId===selectionmodifstate.comment ? 'commentreference': 'highlightreference', index.toString());
          // already highlighted cannot be highlighted again since no selection can be made.
          range.surroundContents(spanHighlight);
          newRangeObjectLocal.setStart(spanHighlight.firstChild, 0);
          newRangeObjectLocal.setEnd(spanHighlight.firstChild, spanHighlight.firstChild.nodeValue.length);
          if(actionId===selectionmodifstate.comment)
            {
              bCommentsChanged.current = true;
              commentsToBeSavedtoDB.current.set(index.toString() , {range: newRangeObjectLocal, comment: '' });
            }
          else
            {
              bHighlightsChanged.current = true;
              highlightsToBeSavedtoDB.current.set(index.toString() , newRangeObjectLocal);
            }
          window.getSelection().removeAllRanges();
          if (actionId===selectionmodifstate.comment)
            {
              setShowCommentBox({rangeofselection: newRangeObjectLocal,  CommentBoxShow: true, reference: index.toString(), comment:''});
            }
         
          DoneWithContextMenu();
          return;
          }
        
       
        // If start and end nodes are the same
        // Each Node will be three nodes 1. textnode before start index 2. highlight span 3. text node after end index.
        // start Node.
        // Todo:: merge contiguious and have one onClick.
        var _iterator = document.createNodeIterator(
          range.commonAncestorContainer,
          NodeFilter.SHOW_ALL, // pre-filter
          {
              // custom filter
              // Already selected then no reselection and overlap is treated as two objects.
              acceptNode: function (node) {
                  return range.intersectsNode(node) && node.nodeValue!= null && node.nodeValue.trim()!=='' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
              }
          }
      );
      var _nodes = [];
      // If there is already a highlight and we select over it then we should not allow.
      // We cannot delete the existing highlight that will take user by surprise.
      while (_iterator.nextNode()) {
          if (_nodes.length === 0 && _iterator.referenceNode !== startNode)
          {
            continue;
          }
        // Already highlighted node.
        if((((_iterator.referenceNode.nodeType===document.ELEMENT_NODE) && (_iterator.referenceNode.hasAttribute('highlightreference'))) || 
        ((_iterator.referenceNode.nodeType===document.TEXT_NODE) && (_iterator.referenceNode.parentElement.hasAttribute('highlightreference')))) || 
        (((_iterator.referenceNode.nodeType===document.ELEMENT_NODE) && (_iterator.referenceNode.hasAttribute('commentreference'))) || 
        ((_iterator.referenceNode.nodeType===document.TEXT_NODE) && (_iterator.referenceNode.parentElement.hasAttribute('commentreference')))))
            {
              if(actionId===selectionmodifstate.comment)
              {
                alert("Selection or Part of selection is already highlighted or commented");
                DoneWithContextMenu();
              }
              else
              {
              alert("Selection or Part of selection is already highlighted or commented");
                DoneWithContextMenu();
              }
              return;
            }
        _nodes.push(_iterator.referenceNode);
          if (_iterator.referenceNode === endNode) 
            {
              break;
            }
      }
 
      // Loop on each of the list of Nodes.
      let newRangeObjectMultiLocal = document.createRange();
      let indexMultiLocal =  SelectionToSaveintoList(startNode, 
        endNode, 
        startIndex, 
        endIndex, range);
      for(var i = 0; i<_nodes.length; i++)
        {
          let rangeLocal = document.createRange();
          let spanHighlightLocal = document.createElement('span');
          spanHighlightLocal.className = actionId===selectionmodifstate.comment ? 'Comment' : 'Highlight';
          if(i===0)
            {
              if( actionId===selectionmodifstate.comment)
                spanHighlightLocal.className += ' showcommentpin';
              rangeLocal.setStart(startNode, startIndex);
              rangeLocal.setEnd(startNode, startNode.nodeValue.length);
            }
          else if(i===_nodes.length-1)
            {
              rangeLocal.setStart(endNode, 0);
              rangeLocal.setEnd(endNode, endIndex);
            }
          else
            {
              if(_nodes[i].nodeType===document.TEXT_NODE)
              {
                rangeLocal.setStart(_nodes[i], 0);
                rangeLocal.setEnd(_nodes[i], _nodes[i].nodeValue.length);
              }
              else if(_nodes[i].nodeType===document.ELEMENT_NODE)
              {

                _nodes[i].parentNode.insertBefore(spanHighlightLocal, _nodes[i]);
                spanHighlightLocal.appendChild(_nodes[i]);
                for(var childMulti=spanHighlight.firstChild; childMulti!==null; childMulti=childMulti.nextSibling) {
                  newRangeObjectMultiLocal.selectNode(childMulti);
                }  

                continue;
              }
            }
            rangeLocal.surroundContents(spanHighlightLocal);
            if(i===0)
              {
                newRangeObjectMultiLocal.setStart(spanHighlightLocal.firstChild, 0);
              }
            if(i===_nodes.length-1)
              {
                newRangeObjectMultiLocal.setEnd(spanHighlightLocal.firstChild, spanHighlightLocal.firstChild.nodeValue.length);
              }
              spanHighlightLocal.setAttribute(actionId===selectionmodifstate.comment ? 'commentreference' : 'highlightreference', indexMultiLocal);      
       }
       if(actionId===selectionmodifstate.comment)
        {
          bCommentsChanged.current = true;
          commentsToBeSavedtoDB.current.set(indexMultiLocal.toString() , {range: newRangeObjectMultiLocal, comment: ""});
          setShowCommentBox({rangeofselection: newRangeObjectMultiLocal,  CommentBoxShow: true, reference: indexMultiLocal.toString(), comment:''});
        }
      else
        {
          bHighlightsChanged.current = true;
          highlightsToBeSavedtoDB.current.set(indexMultiLocal.toString() , newRangeObjectMultiLocal);
        }
    window.getSelection().removeAllRanges();
    DoneWithContextMenu();
  }
  else if(actionId===selectionmodifstate.removehighlight ||  actionId===selectionmodifstate.removecomment)
    {
      // Remove Highlight
      // Nextstep: store and retreive new range objects directly with key value as reference.
      const reference = actionId===selectionmodifstate.removehighlight ? type.highlightreference : type.commentreference;
      if(reference===null)
        return;
      const rangeTobeDeleted = actionId===selectionmodifstate.removehighlight ? highlightsToBeSavedtoDB.current.get(reference) : commentsToBeSavedtoDB.current.get(reference).range;
      let iterator = document.createNodeIterator(
        rangeTobeDeleted.commonAncestorContainer,
        NodeFilter.SHOW_ALL, // pre-filter
        {
            // custom filter
            acceptNode: function (node) {
                return rangeTobeDeleted.intersectsNode(node) && node.nodeValue!= null && node.nodeValue.trim()!=='' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
            }
        });
        let nodes = [];
      while (iterator.nextNode()) {
          nodes.push(iterator.referenceNode);
      }
      for(let ii = 0; ii<nodes.length; ii++)
        {
          // Span Highlight
          let parent = nodes[ii].parentElement;
          // SPan with ref.
          let parent_id = parent.parentElement.getAttribute('id');
          parent.parentElement.insertBefore(nodes[ii], parent);
          parent.parentElement.removeChild(parent);
          document.getElementById(parent_id).normalize();
        }

        if(actionId===selectionmodifstate.removecomment)
          {
            bCommentsChanged.current = true;
            commentsToBeSavedtoDB.current.delete(reference);
          }
        else
          {
            bHighlightsChanged.current = true;
            highlightsToBeSavedtoDB.current.delete(reference);
          }
        DoneWithContextMenu();
    }
}
function OnRevisionScheduleEventHandler(NextRevisionDate, offset, revert)
{
  if(revert===true)
    bRevisionScheduleChanged.current = false;
  else
    bRevisionScheduleChanged.current = true;
  setrevisionSchedule(prev => {return {...prev,
    NextRevisionDate: NextRevisionDate,
    Offset: offset
  }});
}
function SafariPreventDefault(event)
{
  event.stopPropagation();
  event.preventDefault();
  return false;
}
  //todo:: mutation observer JS for undo/redo.
  //todo:: on mouseleeave is at error in case of at the margins.
  function DisplayActionMenu(event)
    {
      event.stopPropagation();
      event.preventDefault();
      var clickX = null;
      var clickY = null;
    var selection =  window.getSelection();
    var contentContextInSelection = { visible: true, type:{ highlightstate:0, highlightreference:null, commentstate:0, commentreference:null } };
    var selstring = selection.toString().trim();
    if(selstring!=="")
    {
    event = event || window.event;
    var src = event.target || event.srcElement;
    // selection with in a highlight or selection
    if(src.hasAttribute("highlightreference") || src.hasAttribute("commentreference"))
      {
        contentContextInSelection = {
          ...contentContextInSelection,
          visible: true,
          type:{
            highlightstate:-1,
            highlightreference:null,
            commentstate: -1,
            commentreference: null
          }
        }
      }
    var clientRect = selection.getRangeAt(0).getBoundingClientRect();
    clickX = clientRect.x  +(clientRect.width/2);
    clickY = clientRect.y  + (clientRect.height)+16;
    }
  else{
    if (event.touches && event.touches[0]) 
    {
        clickX = event.touches[0].clientX;
        clickY = event.touches[0].clientY+16;
    } 
    else if (event.changedTouches && event.changedTouches[0]) 
    {
      clickX = event.changedTouches[0].clientX;
      clickY = event.changedTouches[0].clientY+16;
    }
    else if (event.originalEvent && event.originalEvent.changedTouches[0]) 
    {
        clickX = event.originalEvent.changedTouches[0].clientX;
        clickY = event.originalEvent.changedTouches[0].clientY+16;
    } 
    else if (event.clientX && event.clientY) 
    {
      clickX = event.clientX;
      clickY = event.clientY+16;
    }
  }
  if(clickX!=null && clickY!= null)
    {
      contentMenuSection.current.style.left = clickX + 'px';
      contentMenuSection.current.style.top = clickY+'px';
    }
      if(selstring==='')
        {
          event = event || window.event;
          var src = event.target || event.srcElement;
          var contentContextMenuState = {visible:false, type: {highlightstate: 0, highlightreference: null, commentstate:0, commentreference: null}};
          if(!src.hasAttribute("highlightreference") && !src.hasAttribute("commentreference"))
            {
            setContentContextMenu(contentContextMenuState);
            // Any other click then close comment.
            setShowCommentBox(null);
            return false;
            }
          if(src.hasAttribute("highlightreference"))
            {
              contentContextMenuState = {
                ...contentContextMenu,
                visible: true,
                type:{
                  highlightstate:selectionmodifstate.highlight,
                  highlightreference:src.getAttribute("highlightreference"),
                  commentstate: -1,
                  commentreference: null
                }
              };
            }
          else
            {
              // if comment is clicked upon then show comment with edit and delete option.
              var reference = src.getAttribute("commentreference");
              setShowCommentBox({rangeofselection: commentsToBeSavedtoDB.current.get(reference).range,  
                                  CommentBoxShow: true, 
                                  reference: src.getAttribute("commentreference"), 
                                  comment: commentsToBeSavedtoDB.current.get(reference).comment});
              }
          setContentContextMenu(contentContextMenuState);
          return false;
        }
     
      // check if the selection is purely to be highlight removed.
    setContentContextMenu(contentContextInSelection);
    return false;
    }

    
  // Table support should be tried.
  const optionsEditBox  = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    ['link', 'image'],                       // multimedia.
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'color': [] }, { 'background': ['#4cbf6a'] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],  
    ['clean']                                         // remove formatting button
  ];
  useEffect(() => {
    if (imageSrc && panZoomRef.current) {
      panZoomRef.current.fittedScale(); // Adjusts the image to fit the screen
    }
  }, [imageSrc]);



  return ( 
    <div>
      <NoPrintPage />
      {imageSrc && (<div className="imageViewer"><button className="closeImageViewerbtn" onClick={closeImageViewer}>X</button> <PanZoom ref={panZoomRef} image={imageSrc} /></div>)}
      {screenOrientationwarning === true && <div className="UseLandscapeAdvice">Using landscape orientation is better!!</div>}
      <div className="CPSideBar NoSelection">
        <div className={"CPSideBarWithButton" + ( openMenu ? " CPSidebaractive" : " CPSidebarinactive")}>
          <div className="ContentSBbtn" onClick={()=>{setOpenMenu(!openMenu); document.documentElement.style.marginRight = !openMenu && screenOrientation==="landscape" ? "25%" : "0%";}}>
            <i className={ openMenu ? "arrowright" : "arrowleft"}></i>
          </div>
        <div className="ContentSBTab">
          <div className="ContentSBTabButtons">
          { navmenu && <button className={ContentSBTabState===1 ? "ContentSBTabBtn ContentSBTabBtnActive" : "ContentSBTabBtn"} autoFocus onClick={() => {setContentSBTabState(1)}}>Navigation</button>}
          <button className={ContentSBTabState===2 ? "ContentSBTabBtn ContentSBTabBtnActive" : "ContentSBTabBtn"} onClick={() => setContentSBTabState(2)}>Revision Notes</button>
          </div>
          <div className="separatorafter"></div>
            <div className = 'ContentSBTBContentMaster'>
            {ContentSBTabState===1 && <div className='ContentSBTBContent'><nav className="ContentPageSidebar">
              {navmenu!=null ? navmenu : ""}     
            </nav></div>}
            {ContentSBTabState===2 && <div className={fullScreenChecked===false ? 'SecondCBTab' : 'fullScreenTab'}>
             <div className={fullScreenChecked===false ? 'FullScreenRN' : 'FullScreenRNTab'}>
                  <label className='FullScreenRNL'>Full Screen:</label>
                  <input type="checkbox" className='ToggleFullScreen' onChange={() => {setFullScreenChecked(!fullScreenChecked)}}/>
               </div>
               <div className={fullScreenChecked===false ? 'ContentSBTBContentRE' : 'ContentSBTBContentRETab'}>
                <ReactQuill  modules={{toolbar: optionsEditBox}} value={value} onChange={(newstate) => {bRevisionNotesChanged.current = true ;setValue(newstate)}} placeholder={"Revision Notes"}/>
               </div >
            </div>
            }
            </div>
        </div>
      </div>
      </div>
      <div  className="ContentPageWithContext"  onMouseLeave={()=>    {setContentContextMenu({...contentContextMenu, visible: false}); ; setShowCommentBox(null);}}>
        <div className= 'CM' ref={contentMenuSection}>
         {contentContextMenu.visible===true && <ContentContextMenu type={contentContextMenu.type} PerformAction={PerformAction}/>}
        </div>
        {showCommentBox && showCommentBox.CommentBoxShow===true && <CommentBox key={showCommentBox.reference} rangeofSelection={showCommentBox.rangeofselection} reference={showCommentBox.reference} OnDeleteComment={commentBoxOnDeleteComment} onChangeComment={commentBoxOnChangeComment} prevcomment={showCommentBox.comment}/>}
       
        <div key="ContentHTML" className="ContentPage" onMouseUp ={DisplayActionMenu} onTouchEnd={DisplayActionMenu} dangerouslySetInnerHTML={{__html: contentBody}}>
        </div>
        <RevisionSchedulerButtonsAndTextBox  OnRevisionScheduleEvent={OnRevisionScheduleEventHandler} revisionSchedule={revisionSchedule}/>
      </div>
    </div>
  )
}
// type.state 1 -> highlighted //current  state
// type.state 2 -> underlined //current state
// type.state 4 -> commented // current state
// action 1 -> highlight
// action 2 -> underline
// action 3 -> comment
// action 4 -> un highlight
// action 5 -> un underline
// action 6 -> un comment
/**
 * <div className="CMItem" onClick={() => {PerformAction(type.state&selectionmodifstate.underlined ? 5 : 2, type);}}>
  <img className='CMItemImage' src={process.env.PUBLIC_URL + '/underline.svg'}></img>
  {type.state&selectionmodifstate.underlined ? 'Erase Underline' : 'Underline'}</div>
 */
function ContentContextMenu({type, PerformAction})
{
return ((type.highlightstate!==-1 || type.commentstate!==-1) && <div className="contextMenu NoSelection">
{type.highlightstate!==-1 &&  <div className="CMItem" onTouchEnd = {(event) => { event.stopPropagation(); event.preventDefault(); PerformAction(type.highlightstate&selectionmodifstate.highlight ? selectionmodifstate.removehighlight : selectionmodifstate.highlight, type);}} onClick={(event) => {event.stopPropagation(); event.preventDefault(); PerformAction(type.highlightstate&selectionmodifstate.highlight ? selectionmodifstate.removehighlight : selectionmodifstate.highlight, type);}} >
  <HighlightSvg />
  <div className="HighlightOptionText">
  {type.highlightstate&selectionmodifstate.highlight ? 'Erase Highlight' : 'Highlight'}
  </div>
</div>}
{type.commentstate!==-1 && <div className="CMItem" onTouchEnd = {(event) => { event.stopPropagation(); event.preventDefault(); PerformAction(type.commentstate&selectionmodifstate.comment ? selectionmodifstate.removecomment : selectionmodifstate.comment, type);}} onClick={(event) => {event.stopPropagation(); event.preventDefault(); PerformAction(type.commentstate&selectionmodifstate.comment ? selectionmodifstate.removecomment : selectionmodifstate.comment, type);}} >
  <CommentSvg />
  <div className="CommentOptionText">
  {type.commentstate&selectionmodifstate.comment ? 'Edit Comment' : 'Comment'}
  </div>
</div>}
</div>)
}


