
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './SliderSlide.css'

 function SliderSlide({ imagePath, header, paragraph }) {
    return (
      <div className="slide">
        <div className="slide-text">
          <h2 className='FPheading2'>{header}</h2>
          <p className="SliderSideText">{paragraph}</p>
        </div>
        <img className="slide-image" src={imagePath} alt={header}/>
      </div>
    );
  }
export default function ShowcaseSlider()
{
    const responsiveSlider = {
        ultraLargeDesktop: {
          breakpoint: { max: 4000, min: 3100 },
          items: 6
        },
        superLargeDesktop: {
          breakpoint: { max: 3100, min: 2600 },
          items: 5
        },
        widedesktop: {
          breakpoint: { max: 2600, min: 2100 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 2100, min: 1600 },
          items: 3
        },
      
        tablet: {
          breakpoint: { max: 1600, min: 1100 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 1100, min: 0 },
          items: 1
        }
      };
    return (    <Carousel responsive={responsiveSlider}
                  swipeable={true}
                  draggable
                  showDots={true}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={2000}
                  keyBoardControl={true}
                  pauseOnHover
                  rtl={false}
                  removeArrowOnDeviceType={["tablet", "mobile"]}>
              <SliderSlide header = {"100 percent syllabus coverage"} imagePath={"./100Percent.png"} paragraph={"Achieve full coverage of the UPSC syllabus with our exclusive resources. From Prelims to Mains, leave no stone unturned in your preparation. Study confidently knowing every topic is thoroughly addressed for maximum success."} />
              <SliderSlide header = {"360° Notes"} imagePath={"./360deg.jpg"} paragraph={"Experience an all-encompassing, 360-degree understanding of every topic in one source. Tap into both Prelims-oriented insights and Mains-level analysis without juggling multiple books. Unlike traditional resources, our notes bring breadth and depth together for comprehensive exam success. Imagine having relevant examples and up-to-date stats right at your fingertips—exactly what Mains answers demand."} />
              <SliderSlide header = {"Free Resources"} imagePath={"./FreeResources1.jpg"} paragraph={"Grasp NCERT fundamentals effortlessly with our simplified, exam-focused explanations. Build a solid UPSC foundation without getting bogged down by unnecessary details. Accelerate your learning curve and save time with these clear, concise NCERT breakdowns"} />
              <SliderSlide header = {"Highlight"} imagePath={"./Highlight.jpg"} paragraph={"Highlight crucial insights and never lose track of important concepts. Mark, revisit and revise with ease using our intuitive highlighter tool."} />
              <SliderSlide header = {"Comment"} imagePath={"./Comment.jpg"} paragraph={"Add personalized notes or clarifications on the go with our advanced commenting tool. Add your insights and thoughts right where they matter most."} />
              <SliderSlide header = {"Organise Revisions"} imagePath={"./RevisionScheduler.jpg"} paragraph={"Imagine never missing a key revision—our scheduler does the remembering for you. Simply set a date, then receive a timely reminder to revise notes. Stay on track with ease and watch your progress soar."} />
              <SliderSlide header = {"Searching"} imagePath={"./Search.jpg"} paragraph={"Quickly locate any topic or term across your study material with our powerful search. Save time by pinpointing exactly what you need, right when you need it."} />
              <SliderSlide header = {"Revision Notes"} imagePath={"./RevisionNotes.jpg"} paragraph={"Imagine instantly remembering what matters by jotting down notes in your own words. Because when you personalize your insights, revisiting them becomes a breeze. And the best part is everything sits neatly in one place, keeping your study routine seamless."} />
              <SliderSlide header = {"Take Regular Breaks"} imagePath={"./Pomodoro.jpg"} paragraph={"Stay energized with regular break reminders designed to refresh your mind. Stretch, hydrate and rest to keep both body and brain in top shape. Prioritize wellness alongside preparation for a balanced, effective study routine."} />
              <SliderSlide header = {"Always Stick with Syllabus"} imagePath={"./StickToSyllabus.jpg"} paragraph={"Stay on track with notes organized by the official syllabus instead of broad subjects. Quickly identify coverage gaps and focus on precisely what’s required. Streamline your study strategy with a clear, syllabus-aligned roadmap."} />
              <SliderSlide header = {"Examine Images"} imagePath={"./ImageViewer.jpg"} paragraph={"Zoom in and explore every detail with our interactive image viewer. Enhance visual understanding by closely examining key diagrams and illustrations."} />
          </Carousel>);
}


  