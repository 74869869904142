import React from 'react'
import {ApiKey, razorpayURL, RazorpayAPIkey, paymentUrl, paymentverificationUrl} from '../config.js'
import axios from 'axios';
import './Subscribe.css'



const featuresData = [
  {
    "section": "Content",
    "features": [
      {
        "name": "NCERTs (Explained, Originals)",
        "description": "Provides curated explanations and original text",
        "free": "available",
        "premium": "available"
      },
      {
        "name": "360° Notes",
        "description": "GS1, GS2, GS3 and GS4 - Concise, organised & interconnected notes for quick, holistic understanding, reducing the need for multiple sources",
        "free": "not-available",
        "premium": "available"
      },
      {
        "name": "PYQ Solved (Mains)",
        "description": "Expertly solved answers with insights",
        "free": "not-available",
        "premium": {
                  "text" : "Last 10 years"
                  }
      },
      {
        "name": "Essay Module",
        "description": "Includes structured frameworks, samples, tips",
        "free": "not-available",
        "premium": "available"
      },
    ]
  },
  {
    "section": "Learning Tools",
    "features": [
      {
        "name": "Highlight",
        "description": "Digital highlighting for easy revision",
        "free": "available",
        "premium": "available"
      },
      {
        "name": "Comment",
        "description": "Personalized annotations",
        "free": "available",
        "premium": "available"
      },
      {
        "name": "Revision Notes",
        "description": "Better organization & portability",
        "free": "available",
        "premium": "available"
      },
      {
        "name": "Revision Scheduler",
        "description": "Prioritize and track revisions systematically",
        "free": "available",
        "premium": "available"
      },
      {
        "name": "Search",
        "description": "Quickly search content",
        "free": "available",
        "premium": "available"
      }
    ]
  },
  {
    "section": "Annual Subscription",
    "features": [
      {
        "name": "Cost",
        "description": "for one year",
        "free": {
           "text" : "free"
        },
        "premium": {
          "text" : "10000/-"
          }
      }
    ]
  }
];


export default function Subscribe({msg, backToLogin}) {
  let data;
  function PaymentSuccessHandler(response){
    /*alert(response.razorpay_payment_id);
    alert(response.razorpay_order_id);
    alert(response.razorpay_signature);*/
    response['Callfrom'] = 'ClientHandler';
    response['Username'] = localStorage.getItem('user');
    response['OrderId'] = data.order_id;
    axios.post(paymentverificationUrl, response).then(resp => {
      // todo:: use modal component present here. 
     // https://github.com/machadop1407/React-Modal-Tutorial
     console.log(resp);
     alert(resp.data.message);
     backToLogin();
   }).catch(err => {
     alert(err);
     backToLogin();
    });
  }
  function PaymentFailureHandler(response)
  {
    /*alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);*/
    alert(response.error.code +" : " + response.error.description);
  }

  async function displayRazorPay(e)
    {
      e.preventDefault();
      const razorpayScript = await loadRazorPay();
      if(!razorpayScript)
        {
        alert('Failed to load payment gateway');
        return;
        }
        //Todo:: custom buy option.
        var req = {
          email: localStorage.getItem('user')
        };
        
        const headers = { 
          'x-api-key': ApiKey
        };
        axios.post(paymentUrl, req, { withCredentials: true, headers:headers}).then(resp => {
           // todo:: use modal component present here. 
          // https://github.com/machadop1407/React-Modal-Tutorial
          data = resp.data;
          var options = {
            "key": RazorpayAPIkey, 
            "amount": data.amount, 
            "currency": data.currency,
            "name": "Civilscode",
            "description": "Full Access",
            "image": process.env.PUBLIC_URL + '/logo.svg',
            "order_id": data.order_id, 
            "handler": PaymentSuccessHandler,
            "prefill": {
                "email": localStorage.getItem['user']
              },
            "theme": {
                "color": "#4cbf6a"
            }
        }; 
        var rzpl = new window.Razorpay(options);
        rzpl.on('payment.failed', PaymentFailureHandler);
        rzpl.open();
          alert("Please make a note of this order id: " + data.order_id);
        }).catch(err => { alert(err.response.data.message); });
        
    }
  function loadRazorPay() {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = razorpayURL;
      script.async = true;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      }
      document.body.appendChild(script);
    });
    }
  return (
    <div className="SubscriptionWarning">
      <div className="table-container">
      <table className="comparison-table">
        <thead>
          <tr>
            <th>Features</th>
            <th className='CenterStatus'>Free <br/> (1 Year)</th>
            <th className='CenterStatus'>Premium <br/> (1 Year)</th>
          </tr>
        </thead>
        <tbody>
          {featuresData.map((section) => (
            <>
              <tr key={section.section}>
                <td className="section-heading" colSpan="3">{section.section}</td>
              </tr>
              {section.features.map((feature) => (
                <tr key={feature.name}>
                  <td>
                    <h5>{feature.name}</h5>
                    {feature.description}
                  </td>
                  <td className="CenterStatus">
                    {feature.free.text==null 
                      ? <span className={`status ${feature.free}`}></span>
                      : feature.free.text}
                  </td>
                  <td className="CenterStatus">
                    {feature.premium.text==null 
                      ? <span className={`status ${feature.premium}`}></span>
                      : feature.premium.text}
                  </td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </div>
    <div className='Subscribe'>
      <span>
      {msg}
      <form className='SubscribeForm' onSubmit={displayRazorPay}>
        <button className="SubscribeBtn" type="submit">{"Go Premium"}</button>
      </form>
      </span>
 
      </div>
      </div>
  )
}
